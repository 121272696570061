import React from 'react'
import RcToolTip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'
import './style.scss'

const TooLong = ({
  text,
  children,
  placement = 'topLeft',
  length = 50,
  trigger = ['click', 'hover'],
}) => {
  if (text.length > length) {
    const trim = text.slice(0, length - 3) + '...'
    return (
      <RcToolTip placement={placement} overlay={text} trigger={trigger}>
        {children(trim)}
      </RcToolTip>
    )
  }
  return children(text)
}

export default TooLong
