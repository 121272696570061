import React, { Component } from 'react'
import Modal from 'rc-dialog'
import './style.scss'

class DocumentModal extends Component {
  state = {
    visible: false,
    source: 'original',
  }
  toggle = (source = this.state.source) => {
    this.setState(
      {
        visible: !this.state.visible,
        source,
      },
      () => {
        if (this.state.visible)
          document.documentElement.style.overflow = 'hidden'
        else document.documentElement.style.overflow = 'scroll'
      },
    )
  }

  get instantBook() {
    return (
      <div className="instant-book-wrapper">
        <div className="instant-book-left">
          <div className="instant-book--text">ยืนยันการจองทันที</div>
          <div className="instant-book--icon">
            <img className="light-img" src="/assets/lighting.svg" alt="light" />
          </div>
        </div>
        <div className="instant-book-right">
          <p>
            <span style={{ fontWeight: 'bold' }}>
              สำหรับการจองรถเช่าด้วยบัตรเครดิต
            </span>{' '}
            เมื่อมีการชำระผ่านบัตรเครดิตแล้ว
            บริษัทรถเช่าที่คุณเลือกจะยืนยันการจองทันทีโดย
            <span className="text-success">
              <b>ไม่ต้องรอการอนุมัติ</b>
            </span>
            จากบริษัทรถเช่า โดยจะมีการส่งยืนยันการจองทางอีเมล
          </p>
        </div>
      </div>
    )
  }
  get document() {
    return (
      <React.Fragment>
        <div>
          <p>
            <span style={{ fontWeight: 'bold' }}>
              สำหรับการเช่ารถด้วยเงินสด
            </span>{' '}
            จะมีเจ้าหน้าที่ติดต่อมาภายใน 15 นาทีหลังจากลูกค้ากดจองรถเช่าสำเร็จ
            เพื่อสอบถามข้อมูลและเอกสารสำหรับการยืนยันตัวตนตามข้อมูลด้านล่างเพื่ออนุมัติการเช่า
            ก่อนยืนยันการจอง
          </p>
        </div>
        <div className="rental-step">
          <div className="rental-step--icon">
            <div>
              <i className="flaticon-checked-circle" />
            </div>
            <div>
              <hr />
            </div>
            <div>
              <i className="flaticon-cellphone" />
            </div>
            <div>
              <hr />
            </div>
            <div>
              <i className="flaticon-document" />
            </div>
          </div>
          <div className="rental-step--text">
            <p>ทำการจอง</p>
            <p>เจ้าหน้าที่ติดต่อกลับภายใน 15 นาที เพื่อยืนยันการจอง</p>
            <p>ส่งเอกสาร</p>
          </div>
        </div>
        <p style={{ fontWeight: 'bold' }}>เอกสารสำหรับการยืนยันตัวตน</p>
        <div className="faq-document">
          <div className="row justify-content-around tabs no-gutters">
            <div style={{ marginBottom: 10 }}>
              <div className="row justify-content-center">
                <DocumentBlock
                  index={1}
                  title="บัตรประชาชน หรือ Passport"
                  src={require('../../../static/assets/id_card.svg')}
                />
                <DocumentBlock
                  index={2}
                  title="ใบขับขี่"
                  description="ยังไม่หมดอายุ และได้รับมาไม่ต่ำกว่า 1 ปี"
                  src={require('../../../static/assets/driving_lic.svg')}
                />
                <DocumentBlock
                  index={3}
                  title="เอกสารประกอบอื่นๆ*"
                  description="เช่น ตั๋วเครื่องบิน หรือเอกสารการทำงาน"
                  src={require('../../../static/assets/documents.svg')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="noted-content">
          <p className="mb-0">
            *
            ทางบริษัทรถเช่าอาจเรียกเก็บค่าเช่าล่วงหน้าเพื่อล็อคคิวรถเป็นจำนวนเงิน
            500-1000 บาท{' '}
          </p>
          <p>
            ** กรณีเช่ารถในช่วงวันเทศกาลหรือไฮซีซั่น (เดือนตุลาคมถึงกุมภาพันธ์)
            ทางร้านอาจขอเรียกเก็บค่าล็อคคิวรถในจำนวนที่เพิ่มขึ้นทั้งนี้ขึ้นอยู่กับเงื่อนไขของทางบริษัทรถเช่านั้นๆ
          </p>
        </div>
      </React.Fragment>
    )
  }
  get content() {
    if (this.state.source === 'bigbrand') {
      return this.instantBook
    } else {
      return this.document
    }
  }

  render() {
    const { source } = this.state
    const style = source === 'bigbrand' ? 'modal-position' : ''
    return (
      <Modal
        closable
        destroyOnClose
        visible={this.state.visible}
        onClose={this.toggle}
        animation="fade"
        maskAnimation="fade"
        className={style}
      >
        <div className="container pt-3 pb-3">{this.content}</div>
      </Modal>
    )
  }
}

const DocumentBlock = ({
  src = '',
  title = '',
  description = '',
  index = '',
}) => (
  <div className="col-sm-4 ">
    <div className="block">
      <div style={{ display: 'flex' }}>
        <span className="count">{index}</span>
        <img
          className="m-auto"
          src={src}
          height="35"
          width="48"
          alt="Document"
        />
      </div>
      <h6 className="document_title">{title}</h6>
      <small>{description}</small>
    </div>
  </div>
)

export default DocumentModal
