import React, { Component } from 'react'
import DocumentModal from '../document-modal'
import no_credit_card_icon from '../../../static/assets/badges/no_credit_card.svg'
import credit_card from '../../../static/assets/badges/credit_card.svg'
import pickup from '../../../static/assets/badges/pickup.svg'
import document_required_icon from '../../../static/assets/badges/document.svg'
import './style.scss'

export default class Badge extends Component {
  get local() {
    return (
      <React.Fragment>
        <BadgeContainer
          icon={no_credit_card_icon}
          text="ไม่ต้องใช้บัตรเครดิตจอง"
          background="#FF8D41"
          fontColor="#fff"
        />
        <BadgeContainer
          style={{ cursor: 'pointer' }}
          icon={document_required_icon}
          text={
            <div onClick={() => this.confirmModal.toggle('original')}>
              ต้องใช้เอกสารเพิ่มเติม
              <i
                className="flaticon-information"
                style={{ fontSize: 10, marginLeft: 5 }}
              />
            </div>
          }
          background="#FFD24C"
          fontColor="#000"
        />
      </React.Fragment>
    )
  }
  get bigbrand() {
    return (
      <React.Fragment>
        <BadgeContainer
          icon={credit_card}
          text={
            <span>
              เช่าและรับรถ<b>ด้วยบัตรเครดิตเท่านั้น</b>
            </span>
          }
          background="#0053BA"
          fontColor="#fff"
        />
        <BadgeContainer
          icon={pickup}
          text={
            <div onClick={() => this.confirmModal.toggle('bigbrand')}>
              รับรถที่เคาน์เตอร์สนามบิน
              <i
                className="flaticon-information"
                style={{ fontSize: 10, marginLeft: 5 }}
              />
            </div>
          }
          background="#FFD24C"
          fontColor="#000"
        />
      </React.Fragment>
    )
  }
  render() {
    const { type } = this.props
    return (
      <React.Fragment>
        <DocumentModal ref={ref => (this.confirmModal = ref)} />
        <div className="badge-container">
          {type === 'bigbrand' ? this.bigbrand : this.local}
        </div>
      </React.Fragment>
    )
  }
}

export const BadgeContainer = ({
  icon,
  text,
  fontColor = '#000',
  background = '#fff',
  style = {},
  imgFirst = true,
  imgStyle = {},
}) => (
  <div className="badge" style={{ background, color: fontColor, ...style }}>
    {imgFirst ? (
      <React.Fragment>
        <img style={imgStyle} src={icon} alt={text} />
        <div className="badge-text">{text}</div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="badge-text">{text}</div>
        <img style={imgStyle} src={icon} alt={text} />
      </React.Fragment>
    )}
  </div>
)
