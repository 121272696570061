import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Background from '../components/background'
import Panels from '../components/panels'
import Search from '../components/search'
import SEO from '../components/seo'
import ValueProps from '../components/value-props'
import Contact from '../components/contact'
import CarSection from '../components/car-section'
import PromotionList from '../components/promotion-list'
import HeaderValueProps from '../components/header-value-props'
import StickyButton from '../components/sticky-button'
import { Context, Theme } from 'drivehub-rc'
import './style.scss'

class City extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: true,
    }
  }

  componentDidMount() {
    // NOTE: Check Responsive Window
    const initialWidth = window.innerWidth
    this.setState({
      isMobile: initialWidth <= 768 ? true : false,
    })

    let widthChecker = window.matchMedia('(max-width: 768px)')
    widthChecker.addListener(e => {
      if (e.matches) {
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    })
  }

  render() {
    const data = this.props.data
    const { isMobile } = this.state
    const { markdownRemark, site_description } = data // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark
    const { short_value_props } = site_description.edges[0].node.frontmatter
    const prefix = this.props.pathContext.prefix
    return (
      <Theme>
        <Context isMobile={isMobile}>
          <Layout
            isMobile={isMobile}
            prefix={prefix}
            menuStyle={
              isMobile
                ? null
                : { position: 'absolute', width: '100%', zIndex: 10 }
            }
          >
            <SEO
              prefix={prefix}
              title={frontmatter.header}
              canonical={frontmatter.rel_canonical}
              path={frontmatter.path}
              description={frontmatter.description}
              keywords={frontmatter.keywords}
            />
            <div className="header">
              <Background src={frontmatter.hero} className="header--bg-city">
                <Search
                  prefix={prefix}
                  locationId={frontmatter.location_id}
                  cityId={frontmatter.city_id}
                  name={frontmatter.name}
                  siteDescription={short_value_props}
                  title={frontmatter.title}
                  subTitle={frontmatter.sub_title}
                />
              </Background>
            </div>
            <div className="blog-post">
              <div className="container promotion__container">
                <PromotionList
                  prefix={prefix}
                  isMobile={isMobile}
                  cityId={parseInt(frontmatter.city_id)}
                  isCityPage
                />
              </div>
              <Contact prefix={prefix} />
              <HeaderValueProps
                prefix={prefix}
                siteDescription={short_value_props}
              />
              <CarSection
                prefix={prefix}
                city={frontmatter.name}
                locationId={frontmatter.location_id}
                cityId={frontmatter.city_id}
                isMobile={isMobile}
              />
              <ValueProps prefix={prefix} hero={data.boy01} />
              <Panels
                prefix={prefix}
                panels={frontmatter.panels}
                city={frontmatter.name}
              />
              <div className="blog-post__container">
                <div
                  className="container blog-post__content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>
            </div>
            <StickyButton prefix={prefix} />
          </Layout>
        </Context>
      </Theme>
    )
  }
}

export default City
export const query = graphql`
  query($urlPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $urlPath } }) {
      html
      frontmatter {
        name
        path
        header
        title
        sub_title
        description
        keywords
        rel_canonical
        hero
        location_id
        city_id
        panels {
          title
          content
          show_google_faq
        }
      }
    }
    boy01: file(relativePath: { eq: "assets/boy-01.png" }) {
      childImageSharp {
        fluid(maxHeight: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site_description: allMarkdownRemark(
      filter: { fields: { collection: { eq: "site_description" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            short_value_props {
              content_th
              content_en
            }
          }
        }
      }
    }
  }
`
