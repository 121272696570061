import React, { Component } from 'react'
import Slider from 'react-slick'
import Card from './card'
import LazyLoad from 'react-lazyload'
import EmptyBox from '../empty-box'
import { LoadingWheel } from '../../components/loading'
import './style.scss'

const SlickPrevArrow = props => {
  const { onClick } = props
  return (
    <div
      className={`button ${
        props.isMobile ? 'button--left-mobile' : 'button--left-slick'
      } `}
      onClick={onClick}
    >
      <i className="flaticon-back" />
    </div>
  )
}

const SlickNextArrow = props => {
  const { onClick } = props
  return (
    <div
      className={`button ${
        props.isMobile ? 'button--right-mobile' : 'button--right-slick'
      } `}
      onClick={onClick}
    >
      <i className="flaticon-next" />
    </div>
  )
}
export default class CarList extends Component {
  state = {
    list: [],
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isLoading === false) {
      return {
        list: props.cars,
      }
    }
    return {
      list: state.list,
    }
  }

  get slickSetting() {
    const { list } = this.state

    const settings = {
      dots: false,
      infinite: list.length < 3 ? false : true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      swipeToSlide: true,
      nextArrow: <SlickNextArrow />,
      prevArrow: <SlickPrevArrow />,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
          },
        },
      ],
    }
    return settings
  }

  onClickSliderNext = () => {
    this.slider.slickNext()
  }

  onClickSliderPrev = () => {
    this.slider.slickPrev()
  }

  render() {
    const {
      isLoading,
      isMobile,
      pickupLoSelected,
      displayPickupLocationsId,
      onClickPickup,
      pickupLocations,
      isHomePage,
    } = this.props
    const { list } = this.state

    return (
      <div className="car-list--container">
        {isLoading ? (
          <div
            style={{ minHeight: 350, display: 'flex', alignItems: 'center' }}
          >
            <LoadingWheel height={40} width={40} />
          </div>
        ) : list.length === 0 ? (
          <EmptyBox
            displayPickupLocationsId={displayPickupLocationsId}
            pickupLocations={pickupLocations}
            onClickPickup={onClickPickup}
            pickupLoSelected={pickupLoSelected}
            isHomePage={isHomePage}
          />
        ) : (
          <React.Fragment>
            <LazyLoad>
              <Slider ref={c => (this.slider = c)} {...this.slickSetting}>
                {list.map(car => {
                  return (
                    <Card
                      key={car.id}
                      car={car}
                      dateTime={this.props.dateTime}
                      isMobile={isMobile}
                      isHomePage={isHomePage}
                      pickupLoSelected={pickupLoSelected}
                    />
                  )
                })}
              </Slider>
            </LazyLoad>
            {isMobile && (
              <div className="button-container">
                <SlickPrevArrow
                  onClick={this.onClickSliderPrev}
                  isMobile={isMobile}
                />
                <SlickNextArrow
                  onClick={this.onClickSliderNext}
                  isMobile={isMobile}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}
