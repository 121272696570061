import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import { WEB_SEARCH_LINK } from '../../../config'
import Badge, { BadgeContainer } from '../badge'
import TooLong from '../TooLong'
import local from '../../../static/assets/badges/local.svg'
import inter from '../../../static/assets/badges/inter.svg'
import visaMaster from '../../../static/assets/badges/visa_master.svg'
import './style.scss'

export default class Card extends Component {
  static defaultProps = {
    style: {},
  }
  get typeBadge() {
    if (this.props.car.source === 'original') {
      return (
        <BadgeContainer
          background="#FF9E41"
          fontColor="#fff"
          icon={local}
          text="LOCAL"
          style={{ position: 'absolute', top: 4, left: 4, padding: '0px 3px' }}
        />
      )
    }
    return (
      <React.Fragment>
        <BadgeContainer
          background="#0053BA"
          fontColor="#fff"
          icon={inter}
          text="INTER"
          style={{ position: 'absolute', top: 4, left: 4, padding: '0px 3px' }}
        />
        <BadgeContainer
          background="#D8EDFD"
          fontColor="#0053BA"
          icon={visaMaster}
          text="บัตรเครดิต"
          imgFirst={false}
          imgStyle={{ height: 11 }}
          style={{ position: 'absolute', top: 4, left: 65, padding: '0px 3px' }}
        />
      </React.Fragment>
    )
  }
  calculateDiscountPercent = car => {
    return Math.round(
      ((car.slash_total_price - car.total_price) * 100) / car.slash_total_price,
    )
  }
  renderImageSection = car => {
    const backgroundImg = {
      background: 'url(' + car.image_1 + ')',
    }
    return (
      <React.Fragment>
        <div
          className="car-img"
          style={backgroundImg}
          onClick={() => this.onClickSeeDetail(car)}
        />
        {car.slash_price_per_day ? (
          <div className="car-percent-badge">
            {'-'}
            {this.calculateDiscountPercent(car)}%
          </div>
        ) : (
          ''
        )}
        {this.typeBadge}
      </React.Fragment>
    )
  }
  onClickSeeDetail = car => {
    const { pickupDatetime, reuturnDatetime } = this.props.dateTime
    const { isHomePage } = this.props

    const pickupLo = car.location.id
    const pickupLoSelected = isHomePage
      ? pickupLo
      : this.props.pickupLoSelected.id
    const params = {
      location_id: pickupLoSelected,
      source: car.source,
      pickup_datetime: pickupDatetime.format('YYYY-MM-DD HH:mm'),
      return_datetime: reuturnDatetime.format('YYYY-MM-DD HH:mm'),
    }

    const parsedParams = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&')
    const url = `${WEB_SEARCH_LINK}/car/${car.id}?${parsedParams}`
    window.open(url, '_blank')
  }

  get isBigbrand() {
    return this.props.car.source !== 'original'
  }

  get customCardStyle() {
    const { style, isMobile } = this.props
    delete style.width
    return Object.assign({ width: isMobile ? '100%' : '97%' }, style)
  }
  render() {
    const { car, isHomePage, tabIndex } = this.props
    const { pickupDatetime, reuturnDatetime } = this.props.dateTime
    return (
      <div tabIndex={tabIndex} style={this.customCardStyle}>
        <div className="car-card">
          <div className="image__section">{this.renderImageSection(car)}</div>
          <div className="content__section">
            <div className="header-section">
              <div>
                <p className="car-title">
                  <MediaQuery minWidth={1200}>
                    <TooLong
                      length={18}
                      text={car.title.th}
                      trigger={['click', 'hover']}
                    >
                      {text => <span>{text}</span>}
                    </TooLong>
                  </MediaQuery>
                  <MediaQuery minWidth={992} maxWidth={1199}>
                    <TooLong
                      length={15}
                      text={car.title.th}
                      trigger={['click', 'hover']}
                    >
                      {text => <span>{text}</span>}
                    </TooLong>
                  </MediaQuery>
                  <MediaQuery minWidth={768} maxWidth={991}>
                    <TooLong
                      length={10}
                      text={car.title.th}
                      trigger={['click', 'hover']}
                    >
                      {text => <span>{text}</span>}
                    </TooLong>
                  </MediaQuery>
                  <MediaQuery maxWidth={767}>
                    <TooLong
                      length={15}
                      text={car.title.th}
                      trigger={['click', 'hover']}
                    >
                      {text => <span>{text}</span>}
                    </TooLong>
                  </MediaQuery>
                  {!this.isBigbrand && (
                    <small
                      style={{ fontSize: 10 }}
                      className="sub-text sub-text--gray pl-1"
                    >
                      ได้รถคันที่เลือก
                    </small>
                  )}
                </p>
                <hr />
              </div>
              <div>
                <Price
                  slashPricePerDay={car.slash_price_per_day}
                  pricePerDay={car.price_per_day}
                />
              </div>
            </div>

            <div className="body-section">
              <div
                className="car-detail"
                style={car.slash_price_per_day ? { marginTop: -20 } : {}}
              >
                {isHomePage && (
                  <Pair label="รับรถที่" value={car.location.name_th} />
                )}
                <Pair
                  label="รับรถ"
                  value={`${pickupDatetime.format('DD MMM YY, HH:mm')} น.`}
                />
                <Pair
                  label="คืนรถ"
                  value={`${reuturnDatetime.format('DD MMM YY, HH:mm')} น.`}
                />
              </div>
              <div>
                <Badge type={car.source} />
              </div>
            </div>
            <div
              className="button button--see-detail"
              onClick={() => this.onClickSeeDetail(car)}
            >
              <span>ดูรายละเอียดก่อนจอง</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const Pair = ({ label, value }) => (
  <div className="sub-text">
    <p className="sub-text--gray">{label}</p>
    <p style={{ fontSize: 12 }} className="bold">
      {value}
    </p>
  </div>
)

const Price = ({ slashPricePerDay, pricePerDay }) => (
  <div className="car-detail__right">
    <div className="price-box">
      <div
        className={`${slashPricePerDay ? 'price-text--slash' : ''} price-text`}
      >
        {slashPricePerDay ? (
          <React.Fragment>
            <p className="normal-price">
              {Number(slashPricePerDay).toLocaleString()}
            </p>
            <p className="slash-price">
              {Number(pricePerDay).toLocaleString()}
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="normal-price">
              {Number(pricePerDay).toLocaleString()}
            </p>
          </React.Fragment>
        )}
      </div>
      <p className="sub-text sub-text--gray">
        <small>บาท/วัน</small>
      </p>
    </div>
  </div>
)
