import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'
import { withTranslation } from '../locales'

const ValueProps = ({ prefix = 'th', hero, t }) => (
  <StaticQuery
    query={graphql`
      query {
        site_description: allMarkdownRemark(
          filter: { fields: { collection: { eq: "site_description" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                value_props {
                  content_th
                  title_th
                  content_en
                  title_en
                }
              }
            }
          }
        }
      }
    `}
    render={({ site_description }) => {
      //console.log('Mark:', t('personal.fullName_label'))
      const valueProps = site_description.edges.reduce((p, c) => {
        const values = c.node.frontmatter.value_props.map(v => ({
          title: v[`title_${prefix}`],
          content: v[`content_${prefix}`],
        }))
        return p.concat(values)
      }, [])
      return (
        <div className="container valueprops__container">
          <h2>{t('ad.why_us')}</h2>
          <div className="valueprops__box">
            <div className="valueprops--left">
              <Img
                style={{ width: 267, height: 366 }}
                fluid={hero.childImageSharp.fluid}
              />
            </div>
            <div className="valueprops--right">
              {valueProps.map(({ title, content }) => (
                <div key={title} className="valueprop-item">
                  <i className="flaticon-checked-circle" />
                  <p className="valueprop-item__text--primary">
                    {title} <br />
                    <span className="valueprop-item__text--secondary">
                      {content}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    }}
  />
)
export default withTranslation('homepage')(ValueProps)
