import React, { Component } from 'react'
import CarList from '../car-list'
import moment from 'moment-timezone'
import axios from 'axios'
import hoc from '../hoc'
import queryString from 'query-string'
import { LoadingWheel } from '../../components/loading'

import {
  API_GET_LOCATIONS,
  API_GET_CARS,
  WEB_SEARCH_LINK,
  WEB_LEGACY_LINK,
} from '../../../config'
import './style.scss'

const PICKUP_LOCATION_MOBILE_LENGTH = 2
const PICKUP_LOCATION_DESKTOP_LENGTH = 4
const PICKUP_LOCATION_DESKTOP_LENGTH_HOMEPAGE = 4
const TIME_CONDITION_BETWEEN = [18, 19]
const TIME_CONDITION_AFTER = 20

class CarSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cars: [],
      carAvailable: 0,
      pickupLocations: [],
      displayPickupLocationsId: [],
      isDropDownToggle: false,
      isLoading: true,
      selectPickupLoId: 0,
      fetchCarIndex: 0,
      onClickPickupLo: false,
      otherPickupShow: true,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.cars.length === 0 &&
      state.isLoading === false &&
      state.onClickPickupLo !== true &&
      state.fetchCarIndex <= state.displayPickupLocationsId.length - 1
    ) {
      return {
        fetchCarIndex: state.fetchCarIndex + 1,
      }
    }
    if (
      state.displayPickupLocationsId.includes(state.selectPickupLoId) &&
      state.cars.length === 0 &&
      state.isLoading === false
    ) {
      return {
        otherPickupShow: false,
      }
    }
    return state
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.cars.length === 0 &&
      this.state.onClickPickupLo !== true &&
      nextState.fetchCarIndex <= this.state.displayPickupLocationsId.length - 1
    ) {
      this.fetchCarsNext(
        this.state.displayPickupLocationsId,
        nextState.fetchCarIndex,
      )
      return false
    }
    return true
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.locations !== this.props.locations) {
      const lo = this.fetchLocations(this.props)
      this.fetchPickupLocation(lo)
    }
    if (
      this.state.cars.length !== 0 &&
      this.props.isMobile !== prevProps.isMobile
    ) {
      const lo = this.fetchLocations(this.props)
      this.setState({
        displayPickupLocationsId: this.getDisplayPickupLocationsId(lo),
      })
    }
  }

  fetchLocations = props => {
    const { locations, isHomepage } = props
    let homepageLocations = []
    if (isHomepage) {
      let cities = []
      props.cities.forEach(el => cities.push(parseInt(el.id)))
      const homepageSeleted = locations.filter(el => cities.includes(el.id))
      const locationsWithoutSelect = locations.filter(
        el => !cities.includes(el.id),
      )
      locationsWithoutSelect.unshift(...homepageSeleted)
      homepageLocations = locationsWithoutSelect
    }
    return isHomepage
      ? homepageLocations
      : locations.filter(el => el.id === parseInt(props.cityId, 10))
  }

  fetchPickupLocation = async lo => {
    const { isHomepage } = this.props

    if (!isHomepage) {
      this.moveArrayElement(
        lo[0].locations,
        lo[0].locations.findIndex(
          lo => lo.id === Number(this.props.locationId),
        ),
        0,
      )
    }

    const displayPickupLocationsId = await this.getDisplayPickupLocationsId(lo)

    await this.fetchCars(displayPickupLocationsId[0])
    await this.setState({
      selectPickupLoId: displayPickupLocationsId[0],
      pickupLocations: isHomepage ? lo : lo[0].locations,
      displayPickupLocationsId: displayPickupLocationsId,
      fetchCarIndex: this.state.fetchCarIndex,
    })
  }

  moveArrayElement(array, fromIndex, toIndex) {
    var element = array[fromIndex]
    array.splice(fromIndex, 1)
    array.splice(toIndex, 0, element)
  }

  getDisplayPickupLocationsId = lo => {
    const { isMobile, isHomepage } = this.props
    const displayPickupLocationsId = []
    const lengthCategory = isMobile
      ? PICKUP_LOCATION_MOBILE_LENGTH
      : isHomepage
      ? PICKUP_LOCATION_DESKTOP_LENGTH_HOMEPAGE
      : PICKUP_LOCATION_DESKTOP_LENGTH

    if (isHomepage) {
      lo.filter((el, i) => i < lengthCategory).forEach(el =>
        displayPickupLocationsId.push(el.id),
      )
    } else {
      // lo[0].locations
      //   .filter((el, i) => {
      //     return i < lengthCategory
      //   })
      //   .forEach(el => {
      //     displayPickupLocationsId.push(el.id)
      //   })

      for (let i = 0; i < lengthCategory; i++) {
        if (!lo[0].locations[i]) break
        displayPickupLocationsId.push(lo[0].locations[i].id)
      }
    }

    return displayPickupLocationsId
  }

  fetchCars = pickupLocationId => {
    axios
      .get(this.getCarUrl(pickupLocationId))
      .then(res => res.data)
      .then(res => {
        this.setState({
          cars: res.cars,
          carAvailable: res.count,
          isLoading: false,
          selectPickupLoId: pickupLocationId,
        })
      })
  }

  fetchCarsNext = (displayPickupLocationsId, fetchCarIndex) => {
    if (displayPickupLocationsId.length !== 0) {
      const nextPickupLo = displayPickupLocationsId[fetchCarIndex]
      this.fetchCars(nextPickupLo)
    }
  }
  get dateTime() {
    const pickupDatetime = moment().add(4, 'hours')
    const reuturnDatetime = moment(pickupDatetime).add(1, 'days')

    return {
      pickupDatetime: this.roundUpTime(pickupDatetime),
      reuturnDatetime: this.roundUpTime(reuturnDatetime),
    }
  }

  getCarUrl = pickupLocationId => {
    const { pickupDatetime, reuturnDatetime } = this.dateTime
    const apiQuery = Object.assign({})

    apiQuery.pickup_datetime = pickupDatetime.format(
      'YYYY-MM-DDTHH:mm:00+07:00',
    )
    apiQuery.return_datetime = reuturnDatetime.format(
      'YYYY-MM-DDTHH:mm:00+07:00',
    )
    apiQuery.limit = 10
    apiQuery.offset = 0
    if (this.props.isHomepage) {
      apiQuery.city_id = pickupLocationId
      apiQuery.source = 'original'
    } else {
      apiQuery.location_id = pickupLocationId
    }
    apiQuery.order_by = 'price'

    const parsedQuery = queryString.stringify(apiQuery)

    return `${API_GET_CARS}?${parsedQuery}`
  }

  roundUpTime = dateTime => {
    if (dateTime.minutes() <= 30) {
      const diffTime = 30 - dateTime.minutes()
      const newDateTime = dateTime.add(diffTime, 'minutes')
      return this.checkTimeCondition(newDateTime)
    } else {
      const diffTime = 60 - dateTime.minutes()
      const newDateTime = dateTime.add(diffTime, 'minutes')
      return this.checkTimeCondition(newDateTime)
    }
  }

  checkTimeCondition = dateTime => {
    const timeNow = moment().hours()
    if (TIME_CONDITION_BETWEEN.includes(timeNow)) {
      const newDateTime = moment(dateTime).set({ minute: 0, hour: 22 })
      return newDateTime
    }
    if (timeNow >= TIME_CONDITION_AFTER && timeNow <= 23) {
      const newDateTime = moment(dateTime).set({ minute: 0, hour: 10 })
      // .add(1, "days")
      return newDateTime
    }
    if (timeNow <= 6) {
      const newDateTime = moment(dateTime).set({ minute: 0, hour: 10 })
      return newDateTime
    }
    return dateTime
  }

  onClickPickup = pickupLo => {
    this.setState({
      isLoading: true,
      selectPickupLoId: pickupLo.id,
      isDropDownToggle: false,
      onClickPickupLo: true,
      otherPickupShow: true,
    })

    this.fetchCars(pickupLo.id)
  }

  renderOtherPickupLocation = () => {
    const {
      pickupLocations,
      displayPickupLocationsId,
      selectPickupLoId,
    } = this.state

    return (
      <div
        className={`${this.props.isHomepage &&
          'drop-down--homepage'} drop-down--container`}
      >
        {pickupLocations
          .filter((_, i) => i >= displayPickupLocationsId.length)
          .map(pickupLo => {
            return (
              <div
                key={pickupLo.id}
                className={`${selectPickupLoId === pickupLo.id &&
                  'drop-down--selected'} drop-down-list`}
                onClick={() => this.onClickPickup(pickupLo)}
              >
                <p>รับรถที่จุดรับรถ {pickupLo.name.th}</p>
              </div>
            )
          })}
      </div>
    )
  }
  dropDownToggle = () => {
    this.setState({
      isDropDownToggle: !this.state.isDropDownToggle,
    })
  }

  onClickSearchAll = () => {
    const { pickupDatetime, reuturnDatetime } = this.dateTime
    const { isHomepage } = this.props
    const { selectPickupLoId, pickupLocations } = this.state

    if (isHomepage) {
      const city = pickupLocations
        .filter(el => el.id === selectPickupLoId)[0]
        .name.en.toLowerCase()
      const cityUrl = city.replace(' ', '-')
      const url = `${WEB_LEGACY_LINK}/th/${cityUrl}-car-rental`
      window.open(url, '_blank')
    } else {
      const params = {
        location_id: this.state.selectPickupLoId,
        booking_begin: pickupDatetime.format('YYYY-MM-DD HH:mm'),
        booking_end: reuturnDatetime.format('YYYY-MM-DD HH:mm'),
        limit: 5,
        offset: 0,
        orderby: 'price',
      }
      const parsedParams = queryString.stringify(params)
      const url = `${WEB_SEARCH_LINK}/?${parsedParams}`
      window.open(url, '_blank')
    }
  }

  render() {
    const { city, isMobile, isHomepage } = this.props
    const {
      selectPickupLoId,
      isLoading,
      pickupLocations,
      cars,
      carAvailable,
      isDropDownToggle,
      displayPickupLocationsId,
      otherPickupShow,
    } = this.state

    const todayDateTime = moment()
    let pickupLoSelected = 0
    if (isHomepage) {
      pickupLoSelected = pickupLocations.filter(
        el => el.id === selectPickupLoId,
      )[0]
    } else {
      pickupLoSelected = pickupLocations.filter(
        el => el.id === selectPickupLoId,
      )[0]
    }

    return (
      <div
        className="car-section__container"
        style={{ backgroundColor: '#E9ECEF' }}
      >
        <div className="container section-content">
          <div className="section-content__header">
            <p id="text--blue">
              <strong>
                {isHomepage ? 'รถว่าง' : `รถว่างใน${city}`}

                {todayDateTime.hours() >= 20 ? 'พรุ่งนี้' : 'วันนี้'}
              </strong>
            </p>
            <p className="car-section__date">
              <span>
                <i className="flaticon-calendar" />
              </span>
              {todayDateTime.hours() >= 20
                ? todayDateTime.add('1', 'days').format('DD MMM YYYY')
                : todayDateTime.format('DD MMM YYYY')}
            </p>
          </div>
          {this.props.locations ? (
            <React.Fragment>
              <div className="section-content__location">
                {pickupLocations
                  .filter(el => {
                    return displayPickupLocationsId.includes(el.id)
                  })
                  .map((item, i) => {
                    return (
                      <div
                        key={item.id}
                        className={`${
                          selectPickupLoId === item.id && !isDropDownToggle
                            ? 'location__column--active'
                            : ''
                        } location__column location__column--city`}
                        onClick={() => this.onClickPickup(item)}
                      >
                        <i className="flaticon-car-location-icon" />
                        <div className="text--city">
                          <p
                            className={`${
                              isHomepage ? 'pickup__text--homepage' : ''
                            } pickup__text`}
                          >
                            <span>รับรถที่</span>
                            {item.name.th}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                {pickupLocations.length > 4 && (
                  <div
                    className={`${
                      !displayPickupLocationsId.includes(selectPickupLoId)
                        ? 'location__column--active'
                        : ''
                    } ${
                      !isDropDownToggle ? 'select-active' : ''
                    } location__column location__column--select `}
                    onClick={this.dropDownToggle}
                  >
                    <p
                      className={`${
                        isHomepage ? 'pickup__text--homepage' : ''
                      } pickup__text`}
                    >
                      รับรถที่อื่น
                    </p>
                    <span>
                      <i className="flaticon-down-arrow-1" />
                    </span>
                    {isDropDownToggle && this.renderOtherPickupLocation()}
                  </div>
                )}
              </div>
              <div className="section-content__car-list">
                <CarList
                  cars={cars}
                  isLoading={isLoading}
                  isMobile={isMobile}
                  pickupLoSelected={pickupLoSelected}
                  displayPickupLocationsId={displayPickupLocationsId}
                  pickupLocations={pickupLocations}
                  dateTime={this.dateTime}
                  onClickPickup={this.onClickPickup}
                  isHomePage={isHomepage}
                />
              </div>
              {otherPickupShow ? (
                <div className="section-content__footer">
                  <React.Fragment>
                    <div
                      className="footer-search-box"
                      onClick={this.onClickSearchAll}
                    >
                      <p>
                        {pickupLoSelected
                          ? `ค้นหารถว่างพร้อมรับที่${
                              pickupLoSelected.name.th
                            }ทั้งหมด`
                          : 'ค้นหารถว่างทั้งหมด'}
                        <span>
                          {' '}
                          <i className="flaticon-search" />
                        </span>
                      </p>
                    </div>
                    {isLoading ? (
                      ''
                    ) : (
                      <div className="footer-car-available">
                        <p>
                          พบรถว่างทั้งหมด <b>{carAvailable}</b> คัน
                        </p>
                      </div>
                    )}
                  </React.Fragment>
                </div>
              ) : (
                ''
              )}
            </React.Fragment>
          ) : (
            <div
              style={{ minHeight: 350, display: 'flex', alignItems: 'center' }}
            >
              <LoadingWheel height={40} width={40} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default hoc({
  url: API_GET_LOCATIONS,
  field: 'locations',
})(CarSection)
